var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":_vm.dialogWidth,"scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('app.buttons.edit'))+" "+_vm._s(_vm.$t('app.jugadores.single'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"rounded":"","color":"primary"},on:{"click":_vm.saveItem}},[_vm._v(" "+_vm._s(_vm.$t('app.buttons.save'))+" ")])],1)],1),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('form',[_c('v-list',{attrs:{"three-line":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.usuarios.single')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.usuarios.single'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('app.usuarios.single'),"items":_vm.usuarios,"item-text":"persona.fullname","item-value":"id","error-messages":errors,"required":"","disabled":""},model:{value:(_vm.item.persona_id),callback:function ($$v) {_vm.$set(_vm.item, "persona_id", $$v)},expression:"item.persona_id"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.responsible_name')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.responsible_name'),"rules":("" + (_vm.responsableIsRequired ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":_vm.$t('app.headers.responsible_name'),"error-messages":errors},model:{value:(_vm.item.nombre_persona_responsable),callback:function ($$v) {_vm.$set(_vm.item, "nombre_persona_responsable", $$v)},expression:"item.nombre_persona_responsable"}})]}}],null,true)})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.height')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.height'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":_vm.$t('app.headers.height'),"error-messages":errors,"required":"","suffix":"cm","type":"number"},model:{value:(_vm.item.estatura),callback:function ($$v) {_vm.$set(_vm.item, "estatura", $$v)},expression:"item.estatura"}})]}}],null,true)})],1)],1)],1)],1),_c('v-col',{attrs:{"sm":"6"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.weight')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.weight'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":_vm.$t('app.headers.weight'),"error-messages":errors,"required":"","type":"number","suffix":"kg"},model:{value:(_vm.item.peso),callback:function ($$v) {_vm.$set(_vm.item, "peso", $$v)},expression:"item.peso"}})]}}],null,true)})],1)],1)],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('app.headers.photo'))+" "),_c('small',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm.$t('app.general.photo_dimensions'))+" ")])]),_c('v-list-item-subtitle',[_c('base-file-input',{attrs:{"placeholder":_vm.$t('app.headers.photo'),"icon":"mdi-image","accept":"image/*"},on:{"change":_vm.onChangeControl}}),_c('br'),_c('base-video-record-input',{on:{"on-confirm":_vm.savePhotoTaken}})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.id_type')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.id_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":_vm.$t('app.headers.id_type'),"items":_vm.tipoIdentificacion,"item-text":"nombre","item-value":"id","error-messages":errors,"required":""},model:{value:(_vm.item.tipo_identificacion_id),callback:function ($$v) {_vm.$set(_vm.item, "tipo_identificacion_id", $$v)},expression:"item.tipo_identificacion_id"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.id_number')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.id_number'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":_vm.$t('app.headers.id_number'),"error-messages":errors,"required":""},model:{value:(_vm.item.numero_identificacion),callback:function ($$v) {_vm.$set(_vm.item, "numero_identificacion", $$v)},expression:"item.numero_identificacion"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.id_scans')))]),_c('v-list-item-subtitle',[_c('base-file-input',{attrs:{"placeholder":_vm.$t('app.headers.id_scans'),"icon":"mdi-image","accept":"image/*","multiple":""},on:{"change":_vm.onChangeControlScans}})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.id_verified')))]),_c('v-list-item-subtitle',[_c('v-switch',{staticClass:"pl-5",attrs:{"placeholder":_vm.$t('app.headers.id_verified'),"label":_vm.item.identificacion_verificada ? _vm.$t('app.general.yes') : _vm.$t('app.general.no')},model:{value:(_vm.item.identificacion_verificada),callback:function ($$v) {_vm.$set(_vm.item, "identificacion_verificada", $$v)},expression:"item.identificacion_verificada"}})],1)],1)],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }